<template>
    <div id="nyertesek" class="winners">
        <div class="container-xl">
            <h2 class="text-center mb-5 mt-0 fw-bold text-white">
                Nyertesek
            </h2>
            
            <div class="row pb-5">                
                <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center text-black mb-5 order-1">
                    <p class="m-0 winners__text text-white">                        
                        A napi nyeremények* nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn), míg a heti nyeremények* nyertesei csütörtökönként (<strong>2023. augusztus 10., 2023. augusztus 17., 2023. augusztus 24., 2023. augusztus 31.</strong>) kerülnek kiértesítésre a Lebonyolító által küldött e-mail üzenetben.
                        <br/><br/>
                         *A nyerőidőpontok 8 és 22 óra között kerülnek kisorsolásra.

                    </p>
                </div>       
                <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center text-dark mb-5 order-2">
                    <div class="winners__event p-4">
                        <h5 class="text-red font-secondary text-uppercase fw-bold">Sorsolás</h5>
                        <p class="winners__text text-dark">A fődíjak sorsolására 2023. szeptember 5-én 14:00 órakor kerül sor. A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)</p>
                    </div> 
                </div>         
                <div class="col-12 col-lg-3 text-center d-flex align-items-center justify-content-center order-lg-3 order-4">
                    <img src="@/assets/imgs/winning-price-heti.png" class="img-fluid weekly">
                </div>
                <div class="col-12 col-lg-6 text-center order-lg-4 order-3">
                    <img src="@/assets/imgs/winning-price-fodij.png" class="img-fluid">
                </div>
                <div class="col-12 col-lg-3 text-center d-flex align-items-center justify-content-center order-5">
                    <img src="@/assets/imgs/winning-price-napi.png" class="img-fluid daily mt-3 mt-lg-0">
                </div>
            </div>
            <!-- <div class="col-12 mb-5">
                <p class="text-center text-primary">A nyeremények képe csak illusztráció!</p>
            </div> -->
            <!-- NYertesek -->
            <div class="row">     
                <div class="col-12 col-md-8 offset-md-2">
                    <!-- TAB -->
                    <div class="winners-button-select mb-5" >
                       <button 
                            class="btn" style="border-radius: 30px;"
                            :class="{'bg-red text-white':winnerState === 'napi'}" 
                            v-on:click="setWinnerState('napi')"
                        >
                            Napi <span class="d-none d-md-block ms-1"> nyertesek</span>
                        </button>
                        <button 
                            class="btn" style="border-radius: 30px;"
                            :class="{'bg-red text-white':winnerState === 'heti'}" 
                            v-on:click="setWinnerState('heti')"
                        >
                            Heti <span class="d-none d-md-block ms-1"> nyertesek</span>
                        </button> 
                        <button 
                            class="btn fw-700" style="border-radius:30px;"
                            :class="{'bg-red text-white':winnerState === 'fodij'}" 
                            v-on:click="setWinnerState('fodij')"
                        >
                            Fődij <span class="d-none d-md-block ms-1"> nyertesek</span>
                        </button>
                    </div>
                    <!-- TABLE -->
                    <div class="winners__border mb-5">
                        <div class="winners__table-wrap winners__table-wrap--heading p-3">                        
                            <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                <thead>
                                    <tr class="winners-top font-primary" >
                                        <th class="w-50 text-primary">Nyertes neve</th>
                                        <th class="w-50 text-primary">Város</th>                            
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="winners__table-wrap winners__table-wrap--bg text-dark">                        
                            <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  :class="[`winner-table_${winnerState}`]" >
                                <tbody v-if="filteredWinners && filteredWinners.length && loginShow">                          
                                    <tr class="winners-list" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                        <td class="winners-id w-50 p-3">
                                            <div>{{ winner.name }}</div>                                     
                                        </td>                          
                                        <td class="winners-product text-right text-md-left w-50 py-3">
                                            <span>{{winner.winning_time}}</span>
                                        </td>
                                    </tr>                            
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="loginShow">
                                        <td v-if="winnerState != 'fodij'" class="no-winner text-center py-3" colspan="2">
                                            Hamarosan kiderül kik lettek a nyertesek
                                        </td>
                                        <td v-if="winnerState == 'fodij'" class="no-winner text-center py-3" colspan="2">
                                            Hamarosan kiderül kik lettek a fődíj nyertesek
                                        </td>                                      
                                    </tr>
                                    <tr v-else>
                                        <td class="no-winner text-center py-3" colspan="2">
                                            A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                        
                        </div>

                        
                    </div>

                    <div class="d-flex justify-content-center py-4" v-if="needMoreBtn">
                            <button target="_blank" type="button" class="btn btn-secondary" v-on:click="moreWinners(true)">Mutasd a korábbi nyerteseket</button>
                    </div>
                </div>           
            </div> 
            <p class="text-center fs-14">Ne feledd, mindenképpen őrizd meg a blokkod, hiszen a játékosok csak ennek felmutatásával vehetik át a nyereményüket!</p>
        </div>
    </div>
</template>


<script>
export default {
    data () {
        return {
            winnersAll:[],
            winnerState:'napi',
            needMoreBtn: false,
            needMoreWinners: false
        }
    },
    computed:{
        filteredWinners() {
            let result = []
            //let _this = this
        
            if(this.winnersAll ?? []){                
                
                result = this.winnersAll.filter(w => w.type === this.winnerState)

               /* _this.winnersAll.forEach(w => {
                    if (_this.winnerState == 'napi' || _this.winnerState == 'fodij'){
                        if (w.type === this.winnerState){
                            result.push(w)
                        }
                    } else {
                        if (_this.winnerState == 'heti'){
                            if (w.type != 'napi' && w.type != 'fodij'){                            
                                result.push(w)
                            }
                        }
                    }

                })*/

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            return result;
        }
    },
    methods: {
        setWinnerState(newState) {
            this.moreWinners(false); 
            this.winnerState = newState;      
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        },
        selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                //console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }
    },
    created(){
        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
          //this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })
    }
    
}
</script>