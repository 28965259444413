<template>
    <div id="jatekszabaly" class="rules position-relative block--big">
        <div class="container-xl">

            <div class="row px-2">
                <h2 class="text-black text-center mb-6">
                    A játék lépései
                </h2>
                <div class="col-12 bg-white rules-container">
                    <!-- desctop -->
                    <ul 
                        class="rules-buttons nav nav-pills justify-content-center d-none d-sm-flex w-100" 
                        id="pills-tab" 
                        role="tablist">
                        <li 
                            v-for="(tab, tabind) in tabs"
                            :key="'tab-button-' + tab.id" 
                            role="presentation"
                            class="d-flex justify-content-center align-items-start">
                            <div 
                                class="rules-tab-button d-flex align-items-center" 
                                :class="tab.id == 1 ? 'active' : ''"
                                data-bs-toggle="pill"
                                :data-bs-target="'#pills-' + tab.id"
                                role="tab"
                                aria-selected="true"
                            >
                                <div class="rules-tab-button-content d-flex flex-column">
                                    <div class="d-flex justify-content-center">
                                        <div class="rules-img">
                                            <!-- <img 
                                                :src="tab.img" 
                                                class="img-fluid" 
                                                alt="szabályok"
                                                style="cursor:pointer;"
                                            >  -->
                                            <div class="rule-main-step-img" :class="`rule-main-step-img--${tab.id}`"></div>
                                            <div class="text-center fw-bold text-uppercase text-red mt-4">{{tab.name}}</div>
                                        </div>                                        
                                    </div>                                   
                                </div>
                                
                            </div>
                            <img v-if="tabind < tabs.length - 1" src="@/assets/imgs/step-sep.svg" class="mx-4" style="margin-top:44px"/>
                        </li>
                    </ul>
                    <!-- desctop -->
                    <div 
                        class="tab-content flex-1 d-none d-sm-block" 
                        id="pills-tabContent">                        
                            <div
                                v-for="tab in tabs"
                                :key="'tab-content-' + tab.id" 
                                class="tab-pane fade"
                                :class="tab.id == 1 ? 'show active' : ''" 
                                :id="'pills-' + tab.id" 
                                role="tabpanel" 
                                aria-labelledby="'pills-'+ tab.id + '-tab'"
                            >
                                <div 
                                    class="rules-tab-content d-flex align-items-center"
                                    v-for="(content, i) in tab.content" :key="i"
                                >  
                                    <p class="font-main m-0 text-secondary me-3 pb-4"><img :src="'imgs/'+content[0]" width="75" height="75"></p>
                                    <p class="text-dark pb-3" 
                                        v-html="content[1]"
                                        :class="content[2] == '10' || content[2] == '30' || content[2] == '50' ? 'add-border w-100':''"></p>
                                </div>
                        </div>
                    </div>

                    <!-- MOBILE -->
                    <div id="gameRuleCarousel" class="carousel slide d-block d-sm-none" data-bs-ride="carousel">                        
                        <div class="carousel-inner" >
                            <div class="carousel-item" data-bs-interval="8000"
                                :class="tab.id == 1 ? 'active' : ''"
                                v-for="tab in tabs"
                                :key="tab.id" >
                                <div class="rules-img rules-img--mobile text-center">
                                    <!-- <img 
                                        :src="tab.img" 
                                        class="img-fluid" 
                                        :alt="tab.name"
                                    > -->
                                    <div class="rule-main-step-img" :class="`rule-main-step-img--${tab.id}`"></div>
                                    <div class="text-center fw-bold text-priamry mt-4">{{tab.name}}</div>
                                </div>
                                
                                <div class="rules-tab-content d-flex align-items-center justify-content-center font-secondary mb-4"
                                        v-for="(content, i) in tab.content" :key="i">
                                    <div class="d-flex justify-content-center flex-column align-items-center align-items-md-start">  
                                        <p class="font-main display-1 m-0 text-secondary"><img :src="'imgs/'+content[0]" width="40" height="40"></p>
                                        <p class="text-dark pb-4 m-0" 
                                            v-html="content[1]" 
                                            :class="[content[2] == '10' || content[2] == '30' || content[2] == '50' ? 'add-border':'']"
                                        ></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#gameRuleCarousel" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#gameRuleCarousel" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>         
                <div class="col-12 text-center">
                    <div class="text-primary good-luck">Jó játékot, sok szerencsét kívánunk!</div>
                </div>
                <!-- BUTTONS      -->
                <div class="col-12 flex-wrap flex-md-nowrap justify-content-center d-flex py-7">
                    <button
                        class="btn btn-primary mb-4 mb-md-0 me-0 me-md-4"
                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                        @click="setGtm('Játszom')"
                    >
                        Játszom!
                    </button>
                    <a class="btn btn-secondary " :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat')">
                        Játékszabályzat
                    </a> 
                </div>  
                
            </div> <!-- end of ROW -->
        </div>        
    </div>
</template>

<script>
export default {
    data () {
        return {
             tabs: [
                {
                    id: 1,
                    name: "Vásárolj!",
                    img: "imgs/vasarolj.svg",
                    imgActive: "imgs/vasarolj_active.svg",
                    content:[
                        ["step-1.svg",`Vásárolj egyszerre <strong>legalább 2 db</strong> a promócióban részt vevő <a href="#termekek" style="font-weight:bold;text-decoration:underline">Jana terméket</a> a <strong>Tesco áruházakban</strong>, vagy az <strong><a href="https://bevasarlas.tesco.hu/groceries/" target="_blank">Tesco Online-on</a></strong> (a <a href="https://bevasarlas.tesco.hu/groceries" target="_blank">https://bevasarlas.tesco.hu/groceries/</a> oldalon keresztül).`, "1"],
                        ["step-2.svg",`A nyereményjáték időtartama: <span class='fw-bold text-dark d-block d-lg-inline-block'>2023. augusztus 03 - augusztus 30.</span>`, "2"]
                    ]
                   

                },
                {
                    id: 2,
                    name: "Regisztrálj!",
                    img: "imgs/regisztralj.svg",
                    imgActive: "imgs/regisztralj_active.svg",
                    content: [
                        [
                            "step-3.svg","Regisztrálj, majd töltsd fel a vásárlást igazoló bizonylat adatait és indulj az értékes nyereményekért!", "3"
                        ],
                        [
                            "step-4.svg","A <strong>vásárlást igazoló eredeti bizonylatot</strong> sértetlen, ép állapotban <strong style='white-space:nowrap'><span id='recipe-date'></span>-ig</strong> őrizd meg!", "4"
                        ],
                        [
                            "step-5.svg","Ne feledd, minél <b>többször vásárolsz</b> a promócióban részt vevő Jana termékekből egyidejűleg legalább 2 db-ot, annyiszor <b>több esélyed van a nyerésre!</b>", "5"
                        ]                     
                    ]
                    
                },
                {
                    id: 3,
                    name: "Nyerj!",
                    img: "imgs/nyerj.svg",
                    imgActive: "imgs/nyerj_active.svg",
                    content: [
                        [
                            "step-6.svg","A játék ideje alatt előre meghatározott <b>nyerőidőpontokban</b> megnyerhető <ul class='fw-bold'><li><span class='d-md-none'></span>naponta 1 db 15.000 Ft értékű Tesco vásárlási utalvány</li><li><span class='d-md-none'></span>hetente 1 db 100.000 Ft értékű Tesco vásárlási utalvány</li></ul>", "6"
                        ],
                        [
                            "step-7.svg","A <b>fődíjak 2 db</b> 2 főre szóló 2 éjszakás <b>wellness pihenés a badacsonyi Hotel Bonvinoban.</b>", "7"
                        ],
                        [
                            "step-8.svg","A játék lezárását követően a fődíjak sorsolása: <b>2023. szeptember 05. 14 óra.</b>", "8"
                        ],
                        
                    ]
                    
                }
            ]
        }
    },
    mounted(){
        setTimeout(() => {
            console.log("date", this.$store.state.keep_recipe_date);
            document.getElementById("recipe-date").innerHTML = this.$store.state.keep_recipe_date;    
        }, 1000);
        
    },
    methods:{
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>